<template>
  <pdf :src="src"></pdf>
</template>

<script>
import pdf from "vue-pdf";
export default {
    name : "visor",
    props : {
        src : String
    },
    components : {
        pdf
    }
}
</script>