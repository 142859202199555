<template>
  <div>
    <b-modal
      v-model="showModalVisorPdf"
      ok-title="Descargar"
      cancel-title="Cerrar"
      cancel-variant="danger"
      id="modalVisorPdf"
      title="Imprimir Pdf"
      size="lg"
      @ok="downloadPdf"
    >
      <visor :src="pdfRoute"></visor>
    </b-modal>
    <b-card title="SOLICITUD DE ENVIO">
      <b-row>
        <b-col> No. {{ solicitudId }} </b-col>
      </b-row>
      <br />
      <b-row v-if="datosSolicitud">
        <b-col cols="12">
          <h3>Observaciones:</h3>
        </b-col>
        <b-col cols="12" v-if="datosSolicitud && datosSolicitud.preliquidacion">
          {{ datosSolicitud.preliquidacion.observacion }}
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="12">
          <b-button variant="warning" @click="goBack()" class="mr-1 mb-1">
            <feather-icon
              icon="ArrowLeftIcon"
              aria-hidden="true"
            ></feather-icon>
            Volver
          </b-button>
          <b-button variant="primary" @click="getPdf()" class="mb-1">
            <feather-icon icon="PrinterIcon" aria-hidden="true"></feather-icon>
            Imprimir pdf
          </b-button>
        </b-col>
      </b-row>
      <b-table
        striped
        hover
        responsive
        id="tablePaquetes"
        :items="items"
        :fields="fields"
        table-variant="secondary"
        class="mt-1"
      >
        <template #cell(Tracking)="data" size="sm">
          <b-badge variant="success">
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(Guia)="data" size="sm">
          <b-badge variant="warning">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col sm="12" md="2"><b>TOTAL LBS</b> </b-col>
        <b-col sm="12" md="10" v-if="datosSolicitud && datosSolicitud.preliquidacion">{{
          datosSolicitud.preliquidacion.total_lbs
        }}</b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="2"><b>TRANSPORTE</b></b-col>
        <b-col sm="12" md="10" v-if="datosSolicitud && datosSolicitud.preliquidacion">
          $ {{ datosSolicitud.preliquidacion.valor }}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="2"><b>TRM</b></b-col>
        <b-col sm="12" md="10" v-if="datosSolicitud && datosSolicitud.preliquidacion">
          $ {{ datosSolicitud.preliquidacion.trm.valor }}
          {{ datosSolicitud.preliquidacion.trm.moneda.descripcion }}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="2">
          <b>VALOR ESTIMADO</b>
        </b-col>
        <b-col sm="12" md="10" v-if="datosSolicitud && datosSolicitud.preliquidacion">
          $ {{ datosSolicitud.preliquidacion.valor_moneda_cliente }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span
                >Recuerda que el valor estimado puede estar sujeto a
                cambios.</span
              >
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group
            label-for="i-support"
            label="Adjuntar comprobante de pago"
          >
            <!-- <b-form-file
              v-model="support"
              :state="Boolean(support)"
              placeholder="Seleccione un archivo y arrastrelo aqui ..."
              drop-placeholder="Arrastre el archivo aqui..."
              size="lg"
            ></b-form-file> -->
            <b-button
              v-if="fileLoaded"
              outlined
              variant="danger"
              @click="resetInputFile()"
              style="width: 100%"
            >
              <b-icon icon="x-circle"></b-icon>
              Quitar Archivo
            </b-button>
            <b-button
              v-else
              outlined
              variant="info"
              @click="openInputFile()"
              style="width: 100%"
            >
              <b-icon icon="cloud-upload"></b-icon>
              Seleccionar Archivo
            </b-button>

            <input
              type="file"
              ref="inputFile"
              style="display: none"
              @change="getFileName()"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <br />
          <h4>{{ fileName }}</h4>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="12">
          <b-button outlined variant="primary" @click="sendSupport()">
            <feather-icon icon="SendIcon" aria-hidden="true"></feather-icon>
            Enviar soporte de pago
          </b-button>
        </b-col>
        <!-- <b-col md="1">
          <b-button outlined variant="warning" @click="goBack()">
            <feather-icon icon="ArrowLeftIcon" aria-hidden="true"></feather-icon>
            Volver
          </b-button>
        </b-col> -->
      </b-row>
    </b-card>
  </div>
</template>
<script>
import vSelect from "vue-select";
import visor from "@/views/despachos/visor.vue";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BButton,
  BTable,
  BPagination,
  BIcon,
} from "bootstrap-vue";
export default {
  name: "adjuntar",
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    BIcon,
    vSelect,
    visor,
  },
  data() {
    return {
      pdfRoute: "",
      showModalVisorPdf: false,
      datosSolicitud: {},
      items: [],
      fields: [
        "FechaRecibido",
        "Tracking",
        "Estado",
        "Contenido",
        "Tienda",
        "Guia",
        "Servicio",
        "PesoLb",
        "PesoKg",
        "OrdenCompra",
        "Imagen",
      ],
      support: null,
      fileName: "No se ha seleccionado un archivo",
      fileLoaded: false,
    };
  },
  computed: {
    solicitudId() {
      return this.$route.params.solicitudId;
    },
  },
  created() {},
  mounted() {
    this.getDetail();
  },
  methods: {
    getFileName() {
      this.fileName = this.$refs.inputFile.files[0].name;
      this.support = this.$refs.inputFile.files[0];
      this.fileLoaded = true;
    },
    openInputFile() {
      this.$refs.inputFile.click();
    },
    resetInputFile() {
      this.support = null;
      this.fileLoaded = false;
      this.$refs.inputFile.value = null;
      this.fileName = "No se ha seleccionado un archivo";
    },
    getDetail() {
      this.$http
        .get(
          `api/casillero/despachos/preliquidaciones/datosimpresion/${this.solicitudId}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.datosSolicitud = response.data.datos;
          this.formatDataPackages(this.datosSolicitud.preliquidacion.paquetes);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataPackages(dataFromService) {
      this.items = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {};
        itemFormated = {
          original: itemFromService,
          FechaRecibido: itemFromService.created,
          Tracking: itemFromService.tracking_tienda_casillero,
          Estado: itemFromService.estado,
          Contenido: itemFromService.contenido,
          Guia: itemFromService.hawb,
          Tienda: itemFromService.envio_casillero.tienda.razon_social,
          Servicio: itemFromService.envio_casillero.servicio.descripcion,
          PesoLb: itemFromService.peso_lb,
          PesoKg: itemFromService.peso_kg,
          OrdenCompra: itemFromService.orden_de_compra,
        };
        formatedData.push(itemFormated);
      });
      this.items = formatedData;
    },
    sendSupport() {
      const formData = new FormData();
      formData.append("soportePago", this.support);
      this.$http
        .post(
          `api/casillero/subirsoportedepago/${this.solicitudId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.$swal({
            title: "Solicitar despacho",
            text: "El soporte de pago se cargó correctamente",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((errors) => {
          this.$swal({
            title: "Solicitar despacho",
            text: "Ocurrió un error al cargar el soporte de pago",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getPdf() {
      this.$http
        .get(
          `api/casillero/despachos/preliquidaciones/impresion-pdf/${this.solicitudId}`,
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          this.getBase64(response.data).then((data) => {
            this.pdfRoute = data;
            this.showModalVisorPdf = true;
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    downloadPdf(bvModalEvt) {
      bvModalEvt.preventDefault();
      var link = document.createElement("a");
      link.innerHTML = "Download PDF file";
      link.download = `${this.solicitudId}.pdf`;
      link.href = this.pdfRoute;
      link.click();
    },
    goBack() {
      this.$router.push({ name: "despachos" });
    },
  },
};
</script>